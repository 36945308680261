import PhoneButton from '../Button/PhoneButton';
// import Headshot from '@/images/deprecated/photo-1494790108377-be9c29b29330.png';
import Image from 'next/legacy/image';
import Headshot from '@/images/get-in-touch/lincoln-2.jpg';

export default function GetInTouch() {
  return (
    <div className="bg-gray-100">
      <div className="px-4 py-16 mx-auto text-center max-w-7xl sm:px-6  lg:px-8">
        <div className="mx-auto mb-4 w-28 h-28 xl:h-32 xl:w-32">
          <Image
            src={Headshot}
            alt="Get in touch with America's #1 solar advisory service"
            className="rounded-full "
          />
        </div>
        <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
          <span className="block capitalize">
            Get in touch with America&apos;s #1 solar advisory service
          </span>
        </h2>
        <p className="mx-auto mt-5 text-xl text-gray-700 max-w-prose">
          An EnergyPal solar expert can help you customize the perfect system.
        </p>
        <div className="flex justify-center mt-8">
          <div className="inline-flex rounded-md">
            <PhoneButton />
            {/* <a
              href="#"
              className="inline-flex items-center justify-center px-5 py-3 text-base font-medium text-white border border-transparent rounded-md bg-primary-600 hover:bg-primary-700"
            >
              Get started
            </a> */}
          </div>
          {/* <div className="inline-flex ml-3">
            <a
              href="#"
              className="inline-flex items-center justify-center px-5 py-3 text-base font-medium border border-transparent rounded-md text-primary-700 bg-primary-100 hover:bg-primary-200"
            >
              Learn more
            </a>
          </div> */}
        </div>
      </div>
    </div>
  );
}
