import Image1 from '@/images/featured-on/energy_national_geographic.png';
import Image2 from '@/images/featured-on/energypal_google.png';
import Image3 from '@/images/featured-on/energypal-youtube.png';
import Image4 from '@/images/featured-on/energypal-houzz.png';
import Image5 from 'images/featured-on/Nasdaq-Logo.png';
import Image from 'next/legacy/image';

// Accepting noBg as an optional prop
export default function FeaturedOn({ noBg = false }) {
  const bgColorClass = noBg ? 'bg-transparent' : 'bg-gray-100';

  return (
    <>
      <div className={`relative ${bgColorClass} py-16`}>
        <div className="max-w-md px-4 mx-auto text-center sm:max-w-3xl sm:px-6 lg:max-w-7xl lg:px-8">
          <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl capitalize">
            Our top-rated solar service has been featured on
          </h2>
          <div className="mt-12 mx-auto sm:w-4/6">
            <div className="grid gap-8 grid-cols-2 lg:grid-cols-5 place-items-center">
              <div className="relative flex items-center w-full h-12">
                <Image
                  src={Image1}
                  alt="EnergyPal + National Geographic"
                  layout="fill"
                  objectFit="contain"
                  objectPosition="center center"
                />
              </div>
              <div className="relative flex items-center w-full h-12">
                <Image
                  src={Image5}
                  alt="EnergyPal + Google"
                  layout="fill"
                  objectFit="contain"
                  objectPosition="center center"
                />
              </div>
              <div className="relative flex items-center w-full h-12">
                <Image
                  src={Image2}
                  alt="EnergyPal + Google"
                  layout="fill"
                  objectFit="contain"
                  objectPosition="center center"
                />
              </div>
              <div className="relative flex items-center w-full h-12">
                <Image
                  src={Image3}
                  alt="EnergyPal + YouTube"
                  layout="fill"
                  objectFit="contain"
                  objectPosition="center center"
                />
              </div>
              <div className="relative flex items-center w-full h-12 col-span-2 md:col-span-1">
                <Image
                  src={Image4}
                  alt="EnergyPal + Houzz Pro"
                  layout="fill"
                  objectFit="contain"
                  objectPosition="center center"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
