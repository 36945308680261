import React, { useState, useEffect } from 'react';
import { useRouter } from 'next/router';
import PrimaryButton from '../Button/PrimaryButton';
import Cookies from 'js-cookie';
import { ButtonSize } from '../Button/Button';
import { getCountryCode } from 'lib/tracking';

interface ZipFieldProps {
  cid?: string;
  buttonColor?: string;
  borderColor?: string;
  textSize?: ButtonSize;
  rounded?: boolean;
}

const ZipFirstField = ({
  cid,
  buttonColor,
  borderColor,
  textSize,
  rounded,
}: ZipFieldProps) => {
  const [input, setInput] = useState<string>('');
  const [error, setError] = useState<string>('');
  const [country, setCountry] = useState<string>('US'); // Default to 'US'
  const router = useRouter();

  useEffect(() => {
    // Read country from cookies
    const countryCookie = getCountryCode();
    setCountry(countryCookie || 'UNKNOWN'); // Default to 'US' if cookie is not set
  }, []);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    let value = event.target.value;

    // Convert letters to uppercase for postal code
    if (isNaN(Number(value[0]))) {
      value = value.toUpperCase();
    }

    // Allow backspace to clear input
    if (value === '') {
      setInput(value);
      setError('');
      return;
    }

    // Handle ZIP code input (numbers only, up to 5 digits)
    if (!isNaN(Number(value[0]))) {
      if (/^[0-9]{0,5}$/.test(value)) {
        setInput(value);
        setError('');
      }
    } else {
      // Handle postal code input (Letter Number Letter Number Letter Number)
      const patterns: { [key: string]: RegExp } = {
        '1': /^[A-Z]$/,
        '2': /^[A-Z]\d$/,
        '3': /^[A-Z]\d[A-Z]$/,
        '4': /^[A-Z]\d[A-Z]\d$/,
        '5': /^[A-Z]\d[A-Z]\d[A-Z]$/,
        '6': /^[A-Z]\d[A-Z]\d[A-Z]\d$/,
      };

      const pattern = patterns[value.length.toString()];
      if (pattern && pattern.test(value)) {
        setInput(value);
        setError('');
      }
    }
  };

  const handleSubmit = (): void => {
    const isNumber = !isNaN(Number(input[0]));

    if (isNumber) {
      if (input.length !== 5) {
        setError('Please enter a five-digit zip code.');
        return;
      }
    } else {
      if (input.length !== 6) {
        setError('Please enter a valid postal code.');
        return;
      }
    }
    Cookies.set('sid', 'zip-quote-button');
    {
      cid
        ? router.push(`/quote?zip=${input}&cid=${cid}`)
        : router.push(`/quote?zip=${input}`);
    }
  };

  const placeholder =
    country === 'CA'
      ? 'Enter Postal Code'
      : country === 'US'
      ? 'Enter Zip Code'
      : 'Enter Zip/Postal Code';

  return (
    <div className="flex flex-col">
      <div className="flex flex-col md:flex-row items-left md:items-center">
        <input
          type="text"
          value={input}
          onChange={handleChange}
          placeholder={placeholder}
          className={`px-2 h-12 md:h-14 text-xl md:text-xl mb-2 md:mb-0 border-2  md:rounded-r-none ${
            borderColor ? borderColor : 'border-primary'
          }
          ${
            rounded
              ? 'md:rounded-l-full pl-4 rounded-lg'
              : 'md:rounded-l-xl rounded-xl'
          }`}
        />
        <PrimaryButton
          onClick={handleSubmit}
          className={`text-white rounded-full md:rounded-l-none md:rounded-r-full h-12 md:h-14 ${
            buttonColor ? buttonColor : 'bg-primary'
          }`}
          size={textSize ? textSize : 'xl'}
        >
          Get a Free Quote
        </PrimaryButton>
      </div>
      {error && <p className="text-red-500 text-sm mt-2">{error}</p>}
    </div>
  );
};

export default ZipFirstField;
