import { PhoneIcon } from '@heroicons/react/solid';
import { PHONE_DISPLAY, PHONE_NUMBER } from 'lib/constants';
import { buttonOutlineClasses, ButtonSize } from './Button';

type Props = {
  className?: string;
  size?: ButtonSize; //'xs' | 'sm' | 'md' | 'lg' | 'xl';
};
export default function PhoneButton({ size = 'lg', className = '' }: Props) {
  return (
    <a
      href={`tel:${PHONE_NUMBER}`}
      className={buttonOutlineClasses({ size, className })}
      // className="inline-flex items-center px-6 py-3 text-base font-medium bg-transparent border rounded-full shadow-sm text-primary border-primary hover:bg-primary hover:text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500"
    >
      <PhoneIcon className="w-4 h-4 mr-2" />
      {PHONE_DISPLAY}
    </a>
  );
}
