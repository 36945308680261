import EHouse from '@/images/energypal-icons/e-house-4.png';
import BestExperience from '@/images/energypal-icons/solar-project-experts-4.png';
import BestPrices from '@/images/energypal-icons/best-products-prices-2.png';
import Image from 'next/legacy/image';
import SmartLink from '../Button/SmartLink';

const defaultFeatures = [
  {
    name: 'Your Trusted Advisor',
    description:
      'We listen, educate, and advise. EnergyPal is an unbiased service to assist you in shopping for the best-fit energy solution for your home.',
    icon: EHouse,
  },
  {
    name: 'Better Products & Prices',
    description:
      'Save more, get more with EnergyPal. Rest easy knowing we secured wholesale prices for you on top-quality equipment & warranties.',
    icon: BestPrices,
  },
  {
    name: 'Stress-Free Experience',
    description: (
      <>
        Our personalized service is the easiest way to switch to solar energy
        with confidence. Don&apos;t believe it? Read our{' '}
        <SmartLink
          href="/reviews"
          className="text-primary hover:underline hover:text-primary-700"
        >
          EnergyPal solar reviews
        </SmartLink>
        !
      </>
    ),
    icon: BestExperience,
  },
];

export default function EnergyPalAdvantage({ features = defaultFeatures }) {
  return (
    <div className="pt-16 pb-20 ">
      <div className="max-w-xl px-4 mx-auto sm:px-6 lg:max-w-7xl lg:px-8">
        {/* <h2 className="sr-only">EnergyPal Advantage</h2> */}
        <h2 className="mt-2 text-3xl font-bold tracking-tight text-center text-gray-900 sm:text-4xl">
          The EnergyPal Advantage
        </h2>
        <dl className="mt-10 space-y-10 lg:space-y-0 lg:grid lg:grid-cols-3 lg:gap-12">
          {features.map((feature) => (
            <div key={feature.name}>
              <dt>
                <div className="flex items-center justify-center w-full h-12 rounded-md text-primary md:justify-start">
                  <div className="w-12 h-12">
                    <Image src={feature.icon} alt={feature.name} />
                  </div>
                  {/* <feature.icon className="w-10 h-10" aria-hidden="true" /> */}
                </div>
                <p className="mt-5 text-2xl font-bold leading-6 text-center md:text-left text-gray-900  ">
                  {feature.name}
                </p>
              </dt>
              <dd className="mt-2 text-base text-center md:text-left text-gray-500">
                {feature.description}
              </dd>
            </div>
          ))}
        </dl>
      </div>
    </div>
  );
}
