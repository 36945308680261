import { CheckIcon } from '@heroicons/react/solid';
import { features } from 'data/static';
import Image from 'next/legacy/image';
import LinkButton from '../Button/LinkButton';
import Center from '../Element/Center';

export default function FindTheRightSolution() {
  return (
    <div className="relative py-16 bg-white sm:py-24 lg:py-32">
      <div className="max-w-md px-4 mx-auto text-center sm:max-w-3xl sm:px-6 lg:max-w-7xl lg:px-8">
        {/* <h2 className="text-base font-semibold tracking-wider uppercase text-primary-600">
          Deploy faster
        </h2> */}
        <h2 className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl capitalize">
          Find the right energy solution for your home
        </h2>
        {/* <p className="mx-auto mt-5 text-xl text-gray-500 max-w-prose">
          Phasellus lorem quam molestie id quisque diam aenean nulla in.
          Accumsan in quis quis nunc, ullamcorper malesuada. Eleifend
          condimentum id viverra nulla.
        </p> */}
        <div className="my-12">
          <div className="grid grid-cols-1 gap-8 sm:grid-cols-2 lg:grid-cols-3">
            {features.map((feature) => (
              <div key={feature.name} className="pt-6">
                <div className="flow-root px-6 pb-0 bg-gray-100 rounded-lg">
                  <div className="-mt-2">
                    {/* <div>
                      <span className="inline-flex items-center justify-center p-3 rounded-md shadow-lg bg-primary-500">
                        <feature.icon
                          className="w-6 h-6 text-white"
                          aria-hidden="true"
                        />
                      </span>
                    </div> */}
                    <h3 className="mt-12 text-2xl font-bold tracking-tight text-gray-900">
                      {feature.name}
                    </h3>
                    <div className="w-64 mx-auto mt-4 -mb-8">
                      <Image src={feature.image} alt={feature.name} />
                    </div>
                    {/* <p className="mt-5 text-base text-gray-500">
                      {feature.description}
                    </p> */}
                  </div>
                </div>
                <div className="mx-12 mt-12 mb-6">
                  <p className="my-5 text-xl font-bold text-gray-900 capitalize">
                    {feature.description}
                  </p>
                  <ul className="text-lg list-none">
                    {feature.points.map((point) => (
                      <li key={point} className="mb-4">
                        <div className="relative w-full">
                          <CheckIcon
                            className="absolute block w-6 h-6 text-green-400"
                            aria-hidden="true"
                          />
                          <div className="relative ml-8 text-left">{point}</div>
                        </div>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            ))}
          </div>
        </div>

        <Center>
          <LinkButton href="/compare">Compare Packages</LinkButton>
        </Center>
      </div>
    </div>
  );
}
