import { useState } from 'react';
import ProductImage1 from '@/images/featured-products/solar-panels-6.png';
import ProductImage2 from '@/images/featured-products/battery-2.png';
import ProductImage3 from '@/images/featured-products/no-cost-5.png';
import ProductImage4 from '@/images/featured-products/warranty2.png';
import Image from 'next/legacy/image';
import LinkButton from '../Button/LinkButton';

const tabs = [
  {
    name: 'Solar Panels',
    image: ProductImage1,
    href: '/best-solar-panels-for-homes',
    button: 'Learn more about solar panels',
    description:
      'Produce your own low-cost electricity at home with high efficiency solar panels on your roof or in your yard.',
  },
  {
    name: 'Home Battery',
    image: ProductImage2,
    href: '/best-home-battery-systems',
    button: 'Learn more about home batteries',
    description:
      'Protect your home from utility disruptions & blackouts and store solar power with a home battery.',
  },
  {
    name: '$0 Down Solar',
    image: ProductImage3,
    href: '/solar-financing-lease-loan',
    button: 'Learn more about financing options',
    description:
      'Want immediate savings with no upfront costs? See if you qualify for $0 out-of-pocket solar savings programs.',
  },
  {
    name: 'Warranties',
    image: ProductImage4,
    href: '/home-battery-solar-installers-near-me',
    button: 'Learn more about solar installation',
    description:
      'EnergyPal solar & battery packages come with industry-leading installation and equipment warranties.',
  },
];
function classNames(...classes: string[]): string {
  return classes.filter(Boolean).join(' ');
}
function ProductTabs() {
  const [index, setIndex] = useState(0);
  const tab = tabs[index];
  return (
    <div>
      <div className="mt-8">
        <div className="sm:hidden">
          <label htmlFor="tabs" className="sr-only">
            Select a tab
          </label>
          {/* Use an "onChange" listener to redirect the user to the selected tab URL. */}
          <select
            id="tabs"
            name="tabs"
            className="block w-full border-gray-300 rounded-md focus:ring-primary-500 focus:border-primary-500"
            value={index}
            onChange={(e) => setIndex(Number(e.target.value))}
          >
            {tabs.map((tab, idx) => (
              <option key={tab.name} value={idx}>
                {tab.name}
              </option>
            ))}
          </select>
        </div>
        <div className="hidden sm:block">
          <div className="border-b border-gray-200">
            <nav className="flex -mb-px" aria-label="Tabs">
              {tabs.map((tab, idx) => (
                <span
                  key={tab.name}
                  onClick={() => setIndex(idx)}
                  className={classNames(
                    idx === index
                      ? 'border-primary-500 text-primary-600 font-bold'
                      : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300 font-medium',
                    'w-1/4 py-4 px-1 text-center border-b-2 cursor-pointer'
                  )}
                >
                  {tab.name}
                </span>
              ))}
            </nav>
          </div>
        </div>
      </div>
      <div className="mt-4">
        <Image src={tab.image} alt={tab.name} />

        <h3 className="my-2 text-2xl font-bold tracking-tight text-gray-900 sm:text-3xl">
          {tab.name}
        </h3>
        <p className="mx-auto my-4 text-lg text-gray-700 ">{tab.description}</p>
        <LinkButton href={tab.href}>{tab.button}</LinkButton>
      </div>
    </div>
  );
}

export default function FeaturedProducts() {
  return (
    <>
      <div className="relative py-8 bg-white sm:py-16 lg:py-24">
        <div className="max-w-md px-4 mx-auto text-center sm:max-w-3xl sm:px-6 lg:max-w-7xl lg:px-8">
          <h2 className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
            Featured Products
          </h2>
          <ProductTabs />
        </div>
      </div>
    </>
  );
}
